import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__links"><a href="https://twitter.com/Love_and_war_g" target="_blank" rel="noreferrer">Twitter</a>
        </div>
    </footer>
  );
};

export default Footer;

/*
          <a href="https://discord.com/" target="_blank" rel="noreferrer">Discord</a>
          <a href="https://medium.com/" target="_blank" rel="noreferrer">Blog</a>
*/
