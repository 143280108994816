import React from "react";
import { Route, Switch } from "react-router-dom";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import Footer from "./Footer";
import {Graph04} from "./GraphBlock";

const Page = (props) => {
  return (
    <div className="container">
      <div className="main">
        <ReactMarkdown
          children={props.data.mainText}
          remarkPlugins={[remarkGfm]}
        />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Page;

/*

      <Switch>
        <Route exact path="/economics">
          <div className="graph">
            <Graph04 />
          </div>
        </Route>
      </Switch>
*/
