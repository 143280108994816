import React from "react";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";

const Item = (props) => {
  let history = useHistory();

  function handleClick() {
    history.push("/collection/"+ props.slug);
  }

  let itemData = props.title;
  let itemImage = "/static/work/" + itemData.split(" ").join("_") + ".png";

  return (
    <div className="token__preview" style={{ '--hover-image': 'url(' + itemImage + ')' }} onClick={handleClick}>
      <span>{props.title}</span>
    </div>
  );
};

const Iterator = (item, i) => <Item title={item} slug={i} key={i} />;

const Collection = (props) => {
  return (
    <div className="container container__collection">
      <div className="main">{props.data.map(Iterator)}</div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Collection;
