import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import { useConnectWallet } from '@web3-onboard/react'
import { ethers } from 'ethers'

import { data, items } from "../data";

import Sidebar from "./Sidebar";
import Page from "./Page";
import Collection from "./Collection";
import CollectionItem from "./CollectionItem";
import BottomBar from "./BottomBar";

import { CurrentUserContext } from "../contexts/CurrentUserContext";

const App = () => {
  const history = useHistory();
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()
  const [ethersProvider, setProvider] = useState(null)

  const [currentUser, setCurrentUser] = useState({
    name: "",
  });

  useEffect(() => {
    // If the wallet has a provider than the wallet is connected
    if (wallet?.provider) {
     setProvider(new ethers.providers.Web3Provider(wallet.provider, 'any'))
    }
  }, [wallet])

  /*
          disabled={connecting}
        onClick={connect}>

        */

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <Sidebar data={data} />
      <Switch>
        <Route exact path="/">
          <Page data={data.pages.concept} />
        </Route>
        <Route exact path="/who">
          <Page data={data.pages.who} />
        </Route>
        <Route exact path="/what">
          <Page data={data.pages.what} />
        </Route>
        <Route exact path="/concept">
          <Page data={data.pages.concept} />
        </Route>
        <Route exact path="/collection">
          <Collection data={items} />
        </Route>
        <Route exact path="/collection/:id">
          <CollectionItem data={items} />
        </Route>
      </Switch>
      <BottomBar data={data} />
    </CurrentUserContext.Provider>
  );
};

export default App;


/*

        <Route exact path="/economics">
          <Page data={data.pages.economics} />
        </Route>

*/
