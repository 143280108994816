import React, { useEffect, useState, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

const ItemNav = (props) => {
  const linkSlug = "/" + props.slug;
  return (
    <NavLink
      key={linkSlug}
      to={linkSlug}
      className="link"
      activeClassName="selected"
    >
      {props.title}
    </NavLink>
  );
};

const TitleBlock = (props) => {
  return <h1 className="bottombar__title">{props.title}</h1>;
};

const BottomBar = (props) => {
  const menuEl = useRef(null);
  let location = useLocation();

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (isOpened) {
      setIsOpened(false);
    }
  }, [location]);

  useEffect(() => {
    if (isOpened) {
      menuEl.current.classList.add("opened-menu");
    } else {
      menuEl.current.classList.remove("opened-menu");
    }
  }, [isOpened]);

  const handleToggleMenu = () => {
    if (isOpened) {
      setIsOpened(false);
    } else {
      setIsOpened(true);
    }
  };

  return (
    <div className="bottombar" ref={menuEl}>
      <div className="bottombar__panel">
        <TitleBlock title={props.data.name} />
        <div className="bottombar__buttons">
          <div onClick={handleToggleMenu}>{isOpened ? "" : "Menu"}</div>
          <div className="bottombar_logo">{isOpened ? "" : "L/W"}</div>
        </div>
      </div>
      <div className="bottombar__menu">
        <ItemNav slug="concept" title="Concept" />
        <ItemNav slug="who" title="Who we are" />
        <ItemNav slug="what" title="What we do" />
        <ItemNav slug="collection" title="Collection" />
      </div>
    </div>
  );
};

export default BottomBar;

/*

        <ItemNav slug="economics" title="Economics" />
*/