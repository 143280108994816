import React from "react";
import {
  useParams,
  Link
} from "react-router-dom";

import Footer from "./Footer";

const Item = (props) => {
  var sectionStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: "url(" + props.image + ")",
  };
  return (
    <div className="token__item" style={ sectionStyle }>
      
    </div>
  );
};

const CollectionItem = (props) => {
  let { id } = useParams();

  let prevId = (parseInt(id)===0) ? (54) : (parseInt(id) - 1);
  let nextId = (parseInt(id)===54) ? (0) : (parseInt(id) + 1);

  let itemData = props.data[parseInt(id)];
  let itemImage = "/static/work/" + itemData.split(" ").join("_") + ".png";

  return (
    <div className="container container__collection">
      <div className="main collection__item">
        <div>
          <Item title={itemData} image={itemImage}/>
          <div className="token__arrows">
            <Link to={"/collection/" + prevId}><IconArrowLeft /></Link>
            <Link to={"/collection/" + nextId}><IconArrowRight /></Link>
          </div>
        </div>
        <div className="collection__title">{itemData}</div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default CollectionItem;


function IconArrowLeft(props) {
  return (
    <svg
      width={19}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.9}
        d="M9.568 17.48L.832 9v-.128L9.568.392l1.344 1.472L4.48 7.848h14.048v2.176H4.48l6.432 5.984-1.344 1.472z"
        fill="#379EE9"
      />
    </svg>
  );
}

function IconArrowRight(props) {
  return (
    <svg
      width={18}
      height={18}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.9}
        d="M9.208 17.48l-1.376-1.472 6.432-5.984H.248V7.848h14.016L7.832 1.864 9.208.392l8.704 8.48V9l-8.704 8.48z"
        fill="#379EE9"
      />
    </svg>
  );
}
