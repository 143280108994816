import React from "react";
import {
  NavLink,
} from "react-router-dom";

import { useConnectWallet } from '@web3-onboard/react'

const ItemNav = (props) => {
  const linkSlug = "/" + props.slug;
  return (
    <NavLink
      key={linkSlug}
      to={linkSlug}
      className="link"
      activeClassName="selected"
    >
      {props.title}
    </NavLink>
  );
};

const TitleBlock = (props) => {
  return <h1 className="sidebar__title">{props.title}</h1>;
};

const Sidebar = (props) => {

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

  const handleConnectWallet = async ()=>{
    //props
    console.log('handleConnectWallet')
    
  /*
          disabled={connecting}
        onClick={connect}>

        
    */

        connect()
        .then((data)=>{
          console.log('connect data', data)
        })
        .catch((error)=>{
          console.log('connect error', error)
        })
  }

  const handleDisconnectWallet = ()=>{
    disconnect({ label: wallet.label })
    .then((data)=>{
      console.log('handleDisconnectWallet data', data)
    })
    .catch((error)=>{
      console.log('handleDisconnectWallet error', error)
    })
  }
  return (
    <div className="sidebar">
      <div className="sidebar_inner">
      <TitleBlock title={props.data.name} />
      <ItemNav slug="concept" title="Concept" />
      <ItemNav slug="who" title="Who we are" />
      <ItemNav slug="what" title="What we do" />
      <ItemNav slug="collection" title="Collection" />
      </div>

      <div className="sidebar_inner_bottom">
        {
          wallet?.provider ?         <div className="connect_wallet" onClick={handleDisconnectWallet}>
          Disconnect Wallet
          </div> :

        <div className="connect_wallet" onClick={handleConnectWallet}>
        Connect Wallet
        </div>
        }
        <div className="bottom_logo">
        L/W
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

/*

      <ItemNav slug="economics" title="Economics" />
*/