/*

Collective Unconsciousness Biases

Who we are
What we do
Concept
Collection
Art to help artists
Economics

Connect wallet

*/

const who_we_are = `
**L/W** — transdisciplinary collective of pseudonymous actors come together with the same values and goals.

Our goal is to make art for the benefit of all. 

Our method is to combine our expertise in research-based contemporary art with the latest technology and NFT space. 

We invite you to join us in exploring the world we live in today and reflect on the future we want to create. `;

const what_we_do = `
## Eco NFT’s

We noticed a movement and decided to give it a name — Eco NFT. 

To be Eco, NFT Art should incorporate actions that support positive change. From supporting dog shelters and helping political prisoners to funding research. The methods can vary. But typically, they are some form of connecting the capital with problem-solvers through art on a decentralized market. 

Commonly, but not always, these artworks also build a community around them. Which can potentially amplify the problem-solving power and combine it with the resources that are required for change.  

Let us consider a few examples.

[Censored.art](https://censored.art/ (Censored.art)) — the collection created by Pak & Assange focused on the importance of freedom of speech, but also on fighting against the extradition of Julian Assange to the US. This artwork raised more than $54 Million to fund Assange's legal defense.

[Fishy Fam](https://fishyfam.io/ (Fishy Fam)) — a collection of 9,999 NFT fishies. They claim to use the money they earn to help clean up the oceans. It remains to be seen if they fulfill these promises.   

[Platforms](https://doingud.com/ (Platforms)) — there is a rise in the platforms that connect artists, collectors, and charities. For example, doingud.com allows you to choose from more than 200 charities and send a percentage of the sales to them. 

Eco NFT — a bridge between capital, art, and positive action. 

With our collection, we raise money to help artists and cultural workers. 33% of first sales will go to the charity fund. We will select it together with the collectors.


## Collective not a community 

![Collective not a community](https://collectivebiases.web.app/static/svg/wwdfigure.svg)

We want to find people that share our values and goals. Postnational individuals who value art, freedom of speech, and artistic expression and who want to create a positive change. We focus on quality and compatibility, not quantity. That's why we meet every collector personally.

We believe that the world of NFTs creates quite a unique situation. A new form of relationship between artist, collector, and artwork. This interaction is essential. 

For this reason, we give collectors the power to take part in choosing a course for the collective. Any holder of NFT will get access to our DAO and voting power. 

For instance, with this collection, we want to address the problem of freedom of artistic expression, and collectors will be able to select a charity foundation that helps to solve this issue.

`;

const concept = `
This work explores a generative AI technology, the data this technology is built on, and how the output of this technology represents the world we live in today.

## Intro 

What can the latest technology reveal about ourselves and the world we live in? 
We explore these topics through this collection. To do so, we use Disco Diffusion version 4.1, created by Somnai_dreams. Which is a combination of two machine learning algorithms: Denoising Diffusion Probabilistic model and Clip. 

Disco Diffusion can generate images from text, anything from beautiful landscapes to a logo. But while experimenting with this technology, we stumble upon a question: what if we generate something simple, yet abstract? Like God? We tried a few abstract words and were surprised by the obtained results. So we created a list of words and started to generate them one by one. 

The generative process with Disco Diffusion is unpredictable and unrepeatable. Taking it into account, we generated images for each of the words at least twice. Every iteration took us around 1 hour and 25 minutes to run. We carried out hundreds of experiments for 125 words we choose. When we combine the human time spent and server time, we estimated that to generate this collection we spent more than 1,000 hours. Then we selected the most meaningful results, 55 images in total. 

This means every piece in this collection takes around 18 hours to create. 


## Dataset From User to Technology 

Disco Diffusion is based on the Denoising Diffusion Probabilistic model for image generation. This model was trained on 2 datasets. 

1. [ILSVRC 2012 subset of ImageNet](https://image-net.org/challenges/LSVRC/2012/ (ILSVRC 2012 subset of ImageNet))
2. [LSUN](https://www.yf.io/p/lsun (LSUN))

Let us take a close look at the datasets and how they are created, which will help us to better understand how this process affects the results of image generation. There are similarities and differences across the two datasets, but for now, let’s explore LSUN.

LSUN is created with image data from Google. The first step is to search for certain keywords in Google images. Next, these images were annotated by Amazon Mechanical Turk workers. They labeled the images according to three main categories — fit the description, don’t fit the description, and unknown. Based on these annotations, the machine learning algorithm learns to classify objects by itself. Then it analyzes the remaining search results using this training. This creates the dataset used to train generative models. 

This process affects the output of machine learning algorithms that are trained on this dataset. When you ask the Disco Diffusion to produce an image, you tap into the consciousness and unconsciousness of many actors, including people, media, search engine algorithms, and generative AI.

However, this process introduced a lot of bias along the way. 

We call this a Bias Stack. 


## Bias stack 

![Bias stack](https://collectivebiases.web.app/static/svg/conceptfigure.svg)

Let us explore how biases are introduced and staked.

1. **People and organizations who create and upload images to the internet.** The first level of bias is how we, as individuals, organizations, and collectively as a culture, think about specific phenomena.  

2. **Google search engine algorithm and ranking system.** Every user who searches google will get different results based on their geolocation and internet history. Also, a search engine is not neutral and based on the corporate ethics of the company.   

3. **Amazon Mechanical Turk workers who annotate the images.** These are real people with certain worldview biases and cultural backgrounds. Most of them live in the US and work for a median of 2 dollars per hour.  

4. **Bias in AI algorithm** that hat is trained on the annotations produced by Amazon Mechanical Turk workers. This algorithm classifies images according to its training. These images are used later to create the LSUN dataset.  

5. **The LSUN dataset** was created through this process.

6. **Any AI algorithm trained on this dataset** will include the bias from previous levels. Disco Diffusion is an example of this.  

7. **Artist bias.** We choose pictures that we found interesting and thought-provoking. This is highly subjective and imposes our worldview and bias that come with it. 

8. And the final level is what a **spectator perception perceives** when looking at the generated images.

Combine them all and you have a Bias Stack.

The world we live in through the eyes of a machine 

#### The world we live in through the eyes of a machine

We created 55 images that illustrate how generative AI algorithms we create today propagate the biases of its creators. How culture becomes a technology. 
And how this artistic machine represents the world we live in. 
`;

const collection = `

`;

const art_to_help_artists = `
## Art to help artists

Many artists we know have the similar values. Few of them are: peace, freedom of artistic expression, open boarders and importance of cross-cultural collaboration. 

But today we live in a world that began to fracture. Parade of sovereignties, rise of nationalist movements, authoritarian regimes, dividing wars, are great indicators of that change. 

Some artist and intellectual workers suffer for that shift. Partly because of their world view, party because they can no longer feel safe doing their work. 
So, to address this issue, we chose a problem we think is important today. Issue of free speech, artistic expression, and freedom of creative ideas. To address this issue, we select an area that caught our attention. 

The area of Ukraine, Belarus, and Russia. Intellectual workers in those countries face a serious challenge. Some of them need help to save their families and lives from war, some are preceded for anti-war position inside those countries. Or canceled based on their passport in many countries and art and academic institutions. We think this is wrong. For us, art and ideas are universal, and people who create them should be included, not excluded. Intellectual workers should have a possibility to express through ideas and art freely, based on their morals and ethics, not government politics. Art should include different perspectives and approaches.

Because of that, we will donate 30% of firs sales, and 5% off all secondary sales to 2 charity founds. Founds that helps artist and cultural workers from Ukraine, Russia, and Belarus to deal with difficult situation they may found themselves’s in.

Relocation, money, accommodation, legal issues, advice, and support etc. 
`;

const economics = `
Charity foundation to support artists and cultural workers in Ukraine, Belarus, Russia:

![Economics](https://collectivebiases.web.app/static/svg/economicsfigure.svg)

`;
const data = {
  name: "Collective Unconsciousness Biases",
  pages: {
    concept: {
      title: "Concept",
      mainText: concept,
    },
    who: {
      title: "Who we are",
      mainText: who_we_are,
    },
    what: {
      title: "What we do",
      mainText: what_we_do,
    },
    collection: {
      title: "Collection",
      mainText: collection,
    },
  },
};

/*
    economics: {
      title: "Economics",
      mainText: economics,
    },
*/

const items = [
  "Ai",
  "Art 2",
  "Artist",
  "Bias",
  "Blockchain 2",
  "Border",
  "Captialism",
  "Consciousness",
  "Culture",
  "Decentralization",
  "Devil",
  "Devil 2",
  "Dystopia",
  "Femininity",
  "Femininity 2",
  "Freedom",
  "Future",
  "God",
  "Happiness",
  "Heaven",
  "Hell",
  "Hell 2",
  "Hero",
  "Hope",
  "Human",
  "Human 2",
  "Humanity",
  "Humanity 2",
  "Identity",
  "Identity 2",
  "Ideology",
  "Justice",
  "Leader",
  "Liberty",
  "Masculinity",
  "Masculinity 2",
  "Metaverse 1",
  "Metaverse 2",
  "Money",
  "Nation 1",
  "Nation 2",
  "Passport",
  "Peace",
  "Politics 1",
  "Politics 2",
  "Power 1",
  "Power 2",
  "Religion",
  "State",
  "Success 1",
  "Success 2",
  "Utopia 2",
  "Utopia 1",
  "War 1",
  "War 2",
];

export { data, items };
